import React, { useCallback, useRef, useState } from "react";
import { Carousel } from "primereact/carousel";

type Video = { src: string; ref: React.MutableRefObject<any>; index: number };

function App() {
  const [videos] = useState<Video[]>([
    {
      src: "video_1.mp4",
      ref: useRef<any>(null),
      index: 0,
    },
    {
      src: "video_2.mp4",
      ref: useRef<any>(null),
      index: 1,
    },
    {
      src: "video_3.mp4",
      ref: useRef<any>(null),
      index: 2,
    },
    {
      src: "video_4.mp4",
      ref: useRef<any>(null),
      index: 3,
    },
    {
      src: "video_5.mp4",
      ref: useRef<any>(null),
      index: 4,
    },
    {
      src: "video_6.mp4",
      ref: useRef<any>(null),
      index: 5,
    },
    {
      src: "video_7.mp4",
      ref: useRef<any>(null),
      index: 6,
    },
    {
      src: "video_8.mp4",
      ref: useRef<any>(null),
      index: 7,
    },
    {
      src: "video_9.mp4",
      ref: useRef<any>(null),
      index: 8,
    },
    {
      src: "video_10.mp4",
      ref: useRef<any>(null),
      index: 9,
    },
    {
      src: "video_11.mp4",
      ref: useRef<any>(null),
      index: 10,
    },
    {
      src: "video_12.mp4",
      ref: useRef<any>(null),
      index: 11,
    },
    {
      src: "video_13.mp4",
      ref: useRef<any>(null),
      index: 12,
    },
    {
      src: "video_14.mp4",
      ref: useRef<any>(null),
      index: 13,
    },
  ]);
  const pauseAllVideos = useCallback(() => {
    videos.forEach((v) => {
      if (v.ref.current) {
        v.ref.current.pause();
      }
    });
  }, [videos]);
  const goToPrevPage = useCallback(
    (video: Video) => {
      pauseAllVideos();
      const prevElement: any = document.querySelector(".p-carousel-prev");
      if (prevElement) prevElement.click();
      const prevVideo =
        videos[video.index - 1 < 0 ? videos.length - 1 : video.index - 1];
      if (prevVideo && prevVideo.ref.current) prevVideo.ref.current.play();
    },
    [videos]
  );
  const goToNextPage = useCallback(
    (video: Video) => {
      pauseAllVideos();
      const nextElement: any = document.querySelector(".p-carousel-next");
      if (nextElement) nextElement.click();
      const nextVideo = videos[(video.index + 1) % videos.length];
      if (nextVideo && nextVideo.ref.current) nextVideo.ref.current.play();
    },
    [videos]
  );
  const videoTemplate = (video: Video) => {
    return (
      <article className="video-slide">
        <div
          className="back"
          onClick={() => {
            goToPrevPage(video);
          }}
        >
          <img src="/assets/back.png" />
        </div>
        <video ref={video.ref} controls>
          <source src={`/assets/${video.src}`} type="video/mp4" />
          Your browser does not support the videos.
        </video>
        <div
          className="next"
          onClick={() => {
            goToNextPage(video);
          }}
        >
          <img src="/assets/next.png" />
        </div>
      </article>
    );
  };

  return (
    <div className="slider_containers">
      <Carousel
        page={1}
        value={videos}
        className="carousel"
        circular
        itemTemplate={videoTemplate}
      />
    </div>
  );
}

export default App;
